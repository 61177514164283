import {
  SURVEY_GET_DETAILS,
  SURVEY_GET_DETAILS_SUCCESS,
  SURVEY_GET_DETAILS_ERROR,
  SURVEY_DELETE_QUESTION,
  SURVEY_DELETE_QUESTION_SUCCESS,
  SURVEY_SAVE,
  QUESTIONS_ADD_REQUEST,
  QUESTIONS_ADD_SUCCESS,
  QUESTIONS_EDIT_REQUEST,
  QUESTIONS_EDIT_SUCCESS,
} from '../actions';

const INIT_STATE = {
  survey: null,
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SURVEY_GET_DETAILS:
      return { ...state, loading: false };

    case SURVEY_GET_DETAILS_SUCCESS:
      return { ...state, loading: true, survey: action.payload };

    case SURVEY_GET_DETAILS_ERROR:
      return { ...state, loading: true, error: action.payload };

    case SURVEY_DELETE_QUESTION:
      return { ...state, loading: false };

    case SURVEY_SAVE:
      return { ...state, loading: true, survey: action.payload };

    case QUESTIONS_ADD_REQUEST:
      return { ...state, loading: false };

    case QUESTIONS_ADD_SUCCESS:
      return {
        ...state,
        loading: true,
        survey: {
          ...state.survey,
          survey_questions: state.survey.survey_questions.map((question) => {
            if (question.id === action.payload.id) {
              return action.payload.response;
            }
            return question;
          }),
        },
      };

    case SURVEY_DELETE_QUESTION_SUCCESS:
      return {
        ...state,
        loading: true,
        survey: {
          ...state.survey,
          survey_questions: state.survey.survey_questions.filter(
            (question) => question.id !== action.payload.id
          ),
        },
      };

    case QUESTIONS_EDIT_REQUEST:
      return { ...state, loading: false };

    case QUESTIONS_EDIT_SUCCESS:
      return {
        ...state,
        loading: true,
        survey: {
          ...state.survey,
          survey_questions: state.survey.survey_questions.map((question) => {
            if (question.id === action.payload.id) {
              return action.payload;
            }
            return question;
          }),
        },
      };

    default:
      return { ...state };
  }
};
