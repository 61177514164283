import {
  VOTE_CATEGORY_FAILURE,
  VOTE_CATEGORY_GET_SUCCESS,
  VOTE_CATEGORY_ADD_REQUEST,
  VOTE_CATEGORY_ADD_SUCCESS,
  VOTE_CATEGORY_EDIT_REQUEST,
  VOTE_CATEGORY_EDIT_SUCCESS,
  VOTE_CATEGORY_DELETE_SUCCESS,
  VOTE_FAILURE,
  VOTE_GET_LIST,
  VOTE_GET_LIST_SUCCESS,
  VOTE_ADD_REQUEST,
  VOTE_ADD_SUCCESS,
  VOTE_EDIT_REQUEST,
  VOTE_EDIT_SUCCESS,
  VOTE_DELETE_REQUEST,
  VOTE_DELETE_SUCCESS,
  VOTE_SET_CURRENT_PAGE,
  VOTE_SET_TOTAL_PAGE,
  VOTE_SET_TOTAL_PAGE_SUCCESS,
  VOTE_LIST_GET_LIST_WITH_FILTER,
} from '../actions';

const INIT_STATE = {
  allVoteItems: null,
  allVoteFilterItems: null,
  voteItems: null,
  error: '',
  filter: null,
  loading: false,
  categories: [],
  selectedItems: [],
  currentPage: 1,
  itemsPerPage: 5,
  totalPage: 1,
};

export default (state = INIT_STATE, action) => {
  let allItems = [];
  switch (action.type) {
    case VOTE_CATEGORY_GET_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        loading: true,
        error: null,
      };

    case VOTE_CATEGORY_ADD_REQUEST:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case VOTE_CATEGORY_ADD_SUCCESS:
      return {
        ...state,
        loading: true,
        categories: [...state.categories, action.payload],
      };

    case VOTE_CATEGORY_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };

    case VOTE_CATEGORY_EDIT_REQUEST:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case VOTE_CATEGORY_EDIT_SUCCESS:
      return {
        ...state,
        loading: true,
        categories: state.categories.map((category) =>
          category.id === action.payload.id
            ? { ...category, name: action.payload.name }
            : category
        ),
      };

    case VOTE_CATEGORY_DELETE_SUCCESS:
      return {
        ...state,
        categories: state.categories.filter(
          (category) => category.id !== action.payload.id
        ),
      };

    case VOTE_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };

    case VOTE_GET_LIST:
      return { ...state, loading: false };

    case VOTE_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        allVoteItems: action.payload.total,
        voteItems: action.payload.list,
        filter: null,
      };

    case VOTE_ADD_REQUEST:
      return { ...state, loading: false };

    case VOTE_ADD_SUCCESS:
      allItems = [action.payload, ...state.allVoteItems];

      return {
        ...state,
        loading: true,
        currentPage: 1,
        totalPage: Math.ceil(allItems.length / state.itemsPerPage),
        allVoteItems: allItems,
        voteItems: allItems.slice(0, state.itemsPerPage),
      };

    case VOTE_EDIT_REQUEST:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case VOTE_EDIT_SUCCESS:
      return {
        ...state,
        loading: true,
        allVoteItems: state.allVoteItems.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        }),
        voteItems: state.voteItems.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        }),
      };

    case VOTE_DELETE_REQUEST:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case VOTE_DELETE_SUCCESS:
      allItems = state.allVoteItems.filter(
        (item) => item.id !== action.payload.id
      );
      return {
        ...state,
        loading: true,
        currentPage: 1,
        totalPage: Math.ceil(allItems.length / state.itemsPerPage),
        allVoteItems: allItems,
        voteItems: allItems.slice(0, state.itemsPerPage),
      };

    case VOTE_SET_TOTAL_PAGE:
      return {
        ...state,
        totalPage: action.payload,
      };

    case VOTE_SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case VOTE_SET_TOTAL_PAGE_SUCCESS:
      return {
        ...state,
        loading: true,
        voteItems: action.payload,
      };

    case VOTE_LIST_GET_LIST_WITH_FILTER:
      if (action.payload.column === '' || action.payload.value === '') {
        return {
          ...state,
          totalPage: Math.ceil(state.allVoteItems.length / state.itemsPerPage),
          currentPage: 1,
          loading: true,
          voteItems: state.allVoteItems.slice(0, state.itemsPerPage),
          allVoteFilterItems: null,
          filter: null,
        };
      }
      // eslint-disable-next-line no-case-declarations
      let filteredItems;
      if (action.payload.column === 'voting_category') {
        filteredItems = state.allVoteItems.filter(
          (item) =>
            item.voting_category &&
            item.voting_category.id === action.payload.value
        );
      } else {
        filteredItems = state.allVoteItems.filter(
          (item) => item[action.payload.column] === action.payload.value
        );
      }

      return {
        ...state,
        loading: true,
        totalPage: Math.ceil(filteredItems.length / state.itemsPerPage),
        currentPage: 1,
        voteItems: filteredItems.slice(0, state.itemsPerPage),
        allVoteFilterItems: filteredItems,
        filter: {
          column: action.payload.column,
          value: action.payload.value,
        },
      };

    default:
      return { ...state };
  }
};
