// eslint-disable-next-line import/no-cycle
import {
  SURVEY_GET_DETAILS,
  SURVEY_GET_DETAILS_SUCCESS,
  SURVEY_GET_DETAILS_ERROR,
  SURVEY_DELETE_QUESTION,
  SURVEY_DELETE_QUESTION_SUCCESS,
  SURVEY_SAVE,
  QUESTIONS_ADD_REQUEST,
  QUESTIONS_ADD_SUCCESS,
  QUESTIONS_EDIT_REQUEST,
  QUESTIONS_EDIT_SUCCESS,
} from '../actions';

export const getSurveyDetail = (surveyId) => ({
  type: SURVEY_GET_DETAILS,
  payload: surveyId,
});

export const getSurveyDetailSuccess = (items) => ({
  type: SURVEY_GET_DETAILS_SUCCESS,
  payload: items,
});

export const getSurveyDetailError = (error) => ({
  type: SURVEY_GET_DETAILS_ERROR,
  payload: error,
});

export const deleteSurveyQuestion = (questionId) => ({
  type: SURVEY_DELETE_QUESTION,
  payload: questionId,
});

export const deleteSurveyQuestionSucess = (questionId) => ({
  type: SURVEY_DELETE_QUESTION_SUCCESS,
  payload: questionId,
});

export const saveSurvey = (survey) => ({
  type: SURVEY_SAVE,
  payload: survey,
});

export const addQuestionRequest = (item) => ({
  type: QUESTIONS_ADD_REQUEST,
  payload: item,
});

export const addQuestionSuccess = (item) => ({
  type: QUESTIONS_ADD_SUCCESS,
  payload: item,
});

export const editQuestionRequest = (item) => ({
  type: QUESTIONS_EDIT_REQUEST,
  payload: item,
});

export const editQuestionSuccess = (item) => ({
  type: QUESTIONS_EDIT_SUCCESS,
  payload: item,
});
