import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { servicePath } from 'constants/defaultValues';
import axios from 'axios';
import { NotificationManager } from 'components/common/react-notifications';
import {
  SURVEY_GET_DETAILS,
  SURVEY_DELETE_QUESTION,
  QUESTIONS_ADD_REQUEST,
  QUESTIONS_EDIT_REQUEST,
} from '../actions';

import {
  getSurveyDetailSuccess,
  getSurveyDetailError,
  // saveSurvey,
  addQuestionSuccess,
  deleteSurveyQuestionSucess,
  editQuestionSuccess,
} from './actions';

const apiSurveys = `${servicePath}/surveys`;
const apiQuestions = `${servicePath}/survey-questions`;
const apiAnswers = `${servicePath}/survey-responses`;
const apiResident = `${servicePath}/residents`;

const getSurveyDetailRequest = async ({ payload, token }) => {
  try {
    const surveyResponse = await axios.get(`${apiSurveys}/${payload}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const surveyQuestions = await axios.get(
      `${apiQuestions}?survey=${payload}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const surveyQuestionsWithData = await Promise.all(
      surveyQuestions.data.map(async (question) => {
        const residentsData = await Promise.all(
          question.survey_residents.map(async (responses) => {
            const residentId = responses.resident;
            const residentResponse = await axios.get(
              `${apiResident}/${residentId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            return {
              ...responses,
              resident: `${residentResponse.data.name} ${residentResponse.data.lastname}`,
            };
          })
        );
        return {
          ...question,
          survey_residents: residentsData,
        };
      })
    );

    return {
      ...surveyResponse.data,
      survey_questions: surveyQuestionsWithData,
    };
  } catch (error) {
    console.error('Error al obtener detalles de la encuesta:', error);
    throw error;
  }
};

const deleteQuestionRequest = async ({ payload, token }) => {
  const response = await axios.delete(`${apiQuestions}/${payload}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const addQuestionRequest = async ({ value, token }) => {
  const postAnswersSequentially = async (answers) => {
    return answers.reduce((promiseChain, answer) => {
      return promiseChain.then(async (result) => {
        const response = await axios.post(
          apiAnswers,
          { title: answer },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        result.push(response.data.id);
        return result;
      });
    }, Promise.resolve([]));
  };

  const answerIds = await postAnswersSequentially(value.answers || []);

  const questionData = {
    title: value.title,
    type: value.type,
    survey: value.survey,
    survey_responses: answerIds,
  };

  const response = await axios.post(apiQuestions, questionData, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data;
};

const editQuestionRequest = async ({ payload, token }) => {
  const { id } = payload;
  const answerIds = await Promise.all(
    (payload.answers || []).map(async (item) => {
      const response = await axios.post(
        apiAnswers,
        { title: item },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data.id;
    })
  );
  const questionData = {
    title: payload.title,
    type: payload.type,
    survey: payload.survey,
    survey_responses: answerIds,
  };
  const response = await axios.put(`${apiQuestions}/${id}`, questionData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

function* getSurveyDetailItems({ payload }) {
  const token = yield select((state) => state.authUser.strapiToken);
  try {
    const response = yield call(getSurveyDetailRequest, { payload, token });

    yield put(getSurveyDetailSuccess(response));
  } catch (error) {
    yield put(getSurveyDetailError(error));
    NotificationManager.error(
      '',
      'Error, intente más tarde',
      5000,
      null,
      null,
      ''
    );
  }
}

function* deleteQuestion({ payload }) {
  const token = yield select((state) => state.authUser.strapiToken);

  try {
    const response = yield call(deleteQuestionRequest, { payload, token });
    yield put(deleteSurveyQuestionSucess(response));
    NotificationManager.success(
      '',
      `Se ha eliminado la pregunta exitosamente`,
      4000,
      null,
      null
    );
  } catch (error) {
    yield put(getSurveyDetailError(error));
    NotificationManager.error(
      '',
      'Error al eliminar la pregunta. Intente de nuevo más tarde.',
      5000,
      null,
      null,
      ''
    );
  }
}

function* addQuestion({ payload }) {
  const { value, id } = payload;

  try {
    const token = yield select((state) => state.authUser.strapiToken);
    const response = yield call(addQuestionRequest, { value, token });
    yield put(addQuestionSuccess({ response, id }));
    NotificationManager.success(
      '',
      `Se ha agregado una nueva pregunta`,
      4000,
      null,
      null
    );
  } catch (error) {
    NotificationManager.error(
      '',
      'Error, al agregar pregunta, intente más tarde.',
      5000,
      null,
      null,
      ''
    );
  }
}

function* editQuestion({ payload }) {
  const token = yield select((state) => state.authUser.strapiToken);
  try {
    const response = yield call(editQuestionRequest, { payload, token });
    yield put(editQuestionSuccess(response));
    NotificationManager.success(
      '',
      `Se ha editado exitosamente`,
      4000,
      null,
      null
    );
  } catch (error) {
    yield put(getSurveyDetailError(error));
    NotificationManager.error(
      '',
      'Error al editar. Intente de nuevo más tarde.',
      5000,
      null,
      null,
      ''
    );
  }
}

export function* watchGetDetail() {
  yield takeEvery(SURVEY_GET_DETAILS, getSurveyDetailItems);
}

export function* watchDeleteQuestion() {
  yield takeEvery(SURVEY_DELETE_QUESTION, deleteQuestion);
}

export function* watchAddQuestion() {
  yield takeEvery(QUESTIONS_ADD_REQUEST, addQuestion);
}

export function* watchEditQuestion() {
  yield takeEvery(QUESTIONS_EDIT_REQUEST, editQuestion);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetDetail),
    fork(watchDeleteQuestion),
    fork(watchAddQuestion),
    fork(watchEditQuestion),
  ]);
}
