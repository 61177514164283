// eslint-disable-next-line import/no-cycle
import {
  SURVEY_LIST_GET_LIST,
  SURVEY_LIST_GET_LIST_SUCCESS,
  SURVEY_LIST_GET_LIST_ERROR,
  SURVEY_LIST_GET_LIST_WITH_FILTER,
  SURVEY_LIST_GET_LIST_WITH_ORDER,
  SURVEY_LIST_GET_LIST_SEARCH,
  SURVEY_LIST_ADD_ITEM,
  SURVEY_LIST_ADD_ITEM_SUCCESS,
  SURVEY_LIST_ADD_ITEM_ERROR,
  SURVEY_LIST_SELECTED_ITEMS_CHANGE,
  SURVEY_EDIT_REQUEST,
  SURVEY_EDIT_SUCCESS,
  SURVEY_DELETE_REQUEST,
  SURVEY_DELETE_SUCCESS,
  SURVEY_ERROR,
  CATEGORY_ADD_REQUEST,
  CATEGORY_ADD_SUCCESS,
  CATEGORY_ADD_FAILURE,
  CATEGORY_GET_SUCCESS,
  CATEGORY_GET_FAILURE,
  CATEGORY_EDIT_REQUEST,
  CATEGORY_EDIT_SUCCESS,
  CATEGORY_EDIT_FAILURE,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_DELETE_FAILURE,
  SET_CURRENT_PAGE,
  SET_TOTAL_PAGE,
  SET_TOTAL_PAGE_SUCCESS,
} from '../actions';

export const getSurveyList = () => ({
  type: SURVEY_LIST_GET_LIST,
});

export const getSurveyListSuccess = (items) => ({
  type: SURVEY_LIST_GET_LIST_SUCCESS,
  payload: items,
});

export const getSurveyListError = (error) => ({
  type: SURVEY_LIST_GET_LIST_ERROR,
  payload: error,
});

export const getSurveyListWithFilter = (column, value) => ({
  type: SURVEY_LIST_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getSurveyListWithOrder = (column) => ({
  type: SURVEY_LIST_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getSurveyListSearch = (keyword) => ({
  type: SURVEY_LIST_GET_LIST_SEARCH,
  payload: keyword,
});

export const addSurveyItem = (item) => ({
  type: SURVEY_LIST_ADD_ITEM,
  payload: item,
});

export const addSurveyItemSuccess = (items) => ({
  type: SURVEY_LIST_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addSurveyItemError = (error) => ({
  type: SURVEY_LIST_ADD_ITEM_ERROR,
  payload: error,
});

export const selectedSurveyItemsChange = (selectedItems) => ({
  type: SURVEY_LIST_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});
export const editSurveyRequest = (surveyData) => ({
  type: SURVEY_EDIT_REQUEST,
  payload: surveyData,
});

export const editSurveySuccess = (survey) => ({
  type: SURVEY_EDIT_SUCCESS,
  payload: survey,
});

export const deleteSurveyRequest = (surveyId) => ({
  type: SURVEY_DELETE_REQUEST,
  payload: surveyId,
});

export const deleteSurveySuccess = (surveyId) => ({
  type: SURVEY_DELETE_SUCCESS,
  payload: surveyId,
});

export const surveyError = (error) => ({
  type: SURVEY_ERROR,
  payload: error,
});

export const addCategoryRequest = (categoryData) => ({
  type: CATEGORY_ADD_REQUEST,
  payload: categoryData,
});

export const addCategorySuccess = (category) => ({
  type: CATEGORY_ADD_SUCCESS,
  payload: category,
});

export const addCategoryFailure = (error) => ({
  type: CATEGORY_ADD_FAILURE,
  payload: error,
});

export const getCategoriesSuccess = (categories) => ({
  type: CATEGORY_GET_SUCCESS,
  payload: categories,
});

export const getCategoriesFailure = (error) => ({
  type: CATEGORY_GET_FAILURE,
  payload: error,
});
export const editCategoryRequest = (categoryData) => ({
  type: CATEGORY_EDIT_REQUEST,
  payload: categoryData,
});

export const editCategorySuccess = (category) => ({
  type: CATEGORY_EDIT_SUCCESS,
  payload: category,
});

export const editCategoryFailure = (error) => ({
  type: CATEGORY_EDIT_FAILURE,
  payload: error,
});

export const deleteCategoryRequest = (categoryId) => ({
  type: CATEGORY_DELETE_REQUEST,
  payload: categoryId,
});

export const deleteCategorySuccess = (categoryId) => ({
  type: CATEGORY_DELETE_SUCCESS,
  payload: categoryId,
});

export const deleteCategoryFailure = (error) => ({
  type: CATEGORY_DELETE_FAILURE,
  payload: error,
});

export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: page,
});

export const setTotalPage = (data) => ({
  type: SET_TOTAL_PAGE,
  payload: data,
});

export const getTotalPageSuccess = (items) => ({
  type: SET_TOTAL_PAGE_SUCCESS,
  payload: items,
});
