import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { servicePath } from 'constants/defaultValues';
import axios from 'axios';
import { NotificationManager } from 'components/common/react-notifications';
import {
  VOTE_GET_DETAILS,
  VOTE_ADD_OPTION_REQUEST,
  VOTE_EDIT_OPTION_REQUEST,
  VOTE_DELETE_SESSION_REQUEST,
  VOTE_GET_RESIDENTS_REQUEST,
  VOTE_CHECKIN_REQUEST,
  VOTE_SET_STATUS_SESSION_REQUEST,
  CHECKIN_SAVE_FILE_REQUEST,
  VOTE_ACTIVE_CHECKIN_REQUEST,
} from '../actions';

import {
  getVoteDetailsSuccess,
  addVoteDetailsFailure,
  addVoteOptionSuccess,
  editVoteOptionSuccess,
  deleteVoteSessionSucess,
  getVoteResidentSuccess,
  voteCheckinSuccess,
  voteCheckinFailure,
  setSessionStatusSuccess,
  saveFileResidenteSuccess,
  setActiveCheckinSuccess,
} from './actions';

const apiVote = `${servicePath}/votes`;
const apiSessions = `${servicePath}/voting-sessions`;
const apiOptions = `${servicePath}/voting-options`;
const apiResident = `${servicePath}/residents`;
const apiResidentDetails = `${servicePath}/resident-details`;
const apiAddress = `${servicePath}/addresses`;
const apiCheckin = `${servicePath}/voting-checkins`;
const apiUpload = `${servicePath}/upload`;

const getVoteDetailRequest = async ({ payload, token }) => {
  try {
    const voteResponse = await axios.get(`${apiVote}/${payload}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const voteSessions = await axios.get(`${apiSessions}?vote=${payload}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const voteSessionsWithData = await Promise.all(
      voteSessions.data.map(async (session) => {
        const residentsData = await Promise.all(
          session.voting_residents.map(async (responses) => {
            const residentId = responses.resident;
            const residentResponse = await axios.get(
              `${apiResident}/${residentId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            return {
              ...responses,
              resident: `${residentResponse.data.name} ${residentResponse.data.lastname}`,
            };
          })
        );
        return {
          ...session,
          voting_residents: residentsData,
        };
      })
    );

    return {
      ...voteResponse.data,
      votingSessions: voteSessionsWithData,
    };
  } catch (error) {
    console.error('Error al obtener detalles de la votación:', error);
    throw error;
  }
};
const addOptionsRequest = async ({ value, token }) => {
  const postOptionsSequentially = async (options) => {
    return options.reduce((promiseChain, option) => {
      return promiseChain.then(async (result) => {
        const response = await axios.post(
          apiOptions,
          { title: option },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        result.push(response.data.id);
        return result;
      });
    }, Promise.resolve([]));
  };

  const optionsIds = await postOptionsSequentially(value.options || []);

  const sessionData = {
    title: value.title,
    description: value.description,
    vote: value.vote,
    link: value.link,
    voting_options: optionsIds,
  };

  const response = await axios.post(apiSessions, sessionData, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data;
};
const editSessionRequest = async ({ payload, token }) => {
  const { id } = payload;
  const optionsIds = await Promise.all(
    (payload.options || []).map(async (item) => {
      const response = await axios.post(
        apiOptions,
        { title: item },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data.id;
    })
  );
  const questionData = {
    title: payload.title,
    description: payload.description,
    vote: payload.vote,
    link: payload.link,
    voting_options: optionsIds,
  };
  const response = await axios.put(`${apiSessions}/${id}`, questionData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};
const deleteSessionRequest = async ({ payload, token }) => {
  const response = await axios.delete(`${apiSessions}/${payload}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};
const getVoteResidentsRequest = async ({ vote, token }) => {
  try {
    const address = await axios.get(
      `${apiAddress}?votes=${vote.id}&voteAccess_null=false`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const addressWithData = await Promise.all(
      address.data.map(async (item) => {
        const checkin = await axios.get(
          `${apiCheckin}?vote=${vote.id}&resident=${item.voteAccess.id}&address=${item.id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        return {
          ...item,
          voteAccess: {
            ...item.voteAccess,
            checkin: checkin.data[0],
          },
        };
      })
    );
    return addressWithData;
  } catch (error) {
    console.error('Error al obtener residentes:', error);
    throw error;
  }
};
const addVoteCheckinRequest = async ({ payload, vote, units, user, token }) => {
  try {
    const { check, item } = payload;
    const headers = { Authorization: `Bearer ${token}` };
    const checkinData = {
      vote: vote.id,
      resident: item.voteAccess.id,
      unit: item.id,
      check,
      user,
    };

    const response = await axios.post(`${apiVote}/checkin`, checkinData, {
      headers,
    });

    const updatedUnits = units.map((unit) =>
      unit.id === item.id
        ? {
            ...unit,
            voteAccess: {
              ...unit.voteAccess,
              checkin: response.data?.data,
            },
          }
        : unit
    );

    return updatedUnits;
  } catch (error) {
    NotificationManager.error(
      '',
      'Error al procesar el check-in',
      5000,
      null,
      null,
      ''
    );
    throw error;
  }
};
const setStatusSessionRequest = async ({ payload, token }) => {
  const response = await axios.put(
    `${apiSessions}/${payload.id}`,
    {
      isOpen: !payload.isOpen,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};
const saveFileResidentRequest = async ({ payload, token }) => {
  const formData = new FormData();
  formData.append('files', payload.file);

  const uploadResponse = await axios.post(apiUpload, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });

  const fileData = uploadResponse.data[0];

  const residentDetailsData = {
    name: payload.name,
    file: fileData.id,
    isFileVerified: true,
  };
  const details = await axios.put(
    `${apiResidentDetails}/${payload.details}`,
    residentDetailsData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  return details.data;
};
const activeCheckinRequest = async ({ vote, canCheckin, token }) => {
  const response = await axios.put(
    `${apiVote}/${vote.id}`,
    { canCheckin: !canCheckin },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

function* getVoteDetail({ payload }) {
  const token = yield select((state) => state.authUser.strapiToken);
  try {
    const response = yield call(getVoteDetailRequest, { payload, token });

    yield put(getVoteDetailsSuccess(response));
  } catch (error) {
    yield put(addVoteDetailsFailure(error));
    NotificationManager.error(
      '',
      'Error, intente más tarde',
      5000,
      null,
      null,
      ''
    );
  }
}
function* addVoteOption({ payload }) {
  const { value, id } = payload;

  try {
    const token = yield select((state) => state.authUser.strapiToken);
    const response = yield call(addOptionsRequest, { value, token });
    yield put(addVoteOptionSuccess({ response, id }));
    NotificationManager.success(
      '',
      `Se ha agregado una nueva sesión`,
      4000,
      null,
      null
    );
  } catch (error) {
    yield put(addVoteDetailsFailure(error));
    NotificationManager.error(
      '',
      'Error, al agregar sesión, intente más tarde.',
      5000,
      null,
      null,
      ''
    );
  }
}
function* editSession({ payload }) {
  const token = yield select((state) => state.authUser.strapiToken);
  try {
    const response = yield call(editSessionRequest, { payload, token });
    yield put(editVoteOptionSuccess(response));
    NotificationManager.success(
      '',
      `Se ha editado exitosamente`,
      4000,
      null,
      null
    );
  } catch (error) {
    yield put(addVoteDetailsFailure(error));
    NotificationManager.error(
      '',
      'Error al editar. Intente de nuevo más tarde.',
      5000,
      null,
      null,
      ''
    );
  }
}
function* deleteSession({ payload }) {
  const token = yield select((state) => state.authUser.strapiToken);

  try {
    const response = yield call(deleteSessionRequest, { payload, token });
    yield put(deleteVoteSessionSucess(response));
    NotificationManager.success(
      '',
      `Se ha eliminado la sesión exitosamente`,
      4000,
      null,
      null
    );
  } catch (error) {
    yield put(addVoteDetailsFailure(error));
    NotificationManager.error(
      '',
      'Error al eliminar la sesión. Intente de nuevo más tarde.',
      5000,
      null,
      null,
      ''
    );
  }
}
function* getResidents() {
  const token = yield select((state) => state.authUser.strapiToken);
  const vote = yield select((state) => state.voteDetailApp.vote);

  try {
    const response = yield call(getVoteResidentsRequest, {
      vote,
      token,
    });

    yield put(getVoteResidentSuccess(response));
  } catch (error) {
    yield put(voteCheckinFailure(error));
    NotificationManager.error(
      '',
      'Error, intente más tarde',
      5000,
      null,
      null,
      ''
    );
  }
}
function* addVoteCheckin({ payload }) {
  const token = yield select((state) => state.authUser.strapiToken);
  const vote = yield select((state) => state.voteDetailApp.vote);
  const units = yield select((state) => state.voteDetailApp.units);
  const currentUser = yield select((state) => state.authUser.currentUser);

  try {
    const response = yield call(addVoteCheckinRequest, {
      payload,
      vote,
      units,
      user: currentUser,
      token,
    });

    yield put(voteCheckinSuccess(response));
  } catch (error) {
    yield put(voteCheckinFailure(error));
    NotificationManager.error(
      '',
      'Error, intente más tarde',
      5000,
      null,
      null,
      ''
    );
  }
}
function* setStatusSession({ payload }) {
  const token = yield select((state) => state.authUser.strapiToken);

  try {
    const response = yield call(setStatusSessionRequest, {
      payload,
      token,
    });

    yield put(setSessionStatusSuccess(response));
  } catch (error) {
    yield put(addVoteDetailsFailure(error));
    NotificationManager.error(
      '',
      'Error, intente más tarde',
      5000,
      null,
      null,
      ''
    );
  }
}
function* saveFileResident({ payload }) {
  const token = yield select((state) => state.authUser.strapiToken);

  try {
    const response = yield call(saveFileResidentRequest, {
      payload,
      token,
    });

    yield put(saveFileResidenteSuccess(response));
  } catch (error) {
    yield put(addVoteDetailsFailure(error));
    NotificationManager.error(
      '',
      'Error, al subir archivo, intente más tarde',
      5000,
      null,
      null,
      ''
    );
  }
}
function* activeCheckin() {
  const token = yield select((state) => state.authUser.strapiToken);
  const vote = yield select((state) => state.voteDetailApp.vote);

  const canCheckin = yield select((state) => state.voteDetailApp.canCheckin);

  try {
    const response = yield call(activeCheckinRequest, {
      vote,
      canCheckin,
      token,
    });

    yield put(setActiveCheckinSuccess(response));
  } catch (error) {
    yield put(voteCheckinFailure(error));
    NotificationManager.error(
      '',
      'Error, intente más tarde',
      5000,
      null,
      null,
      ''
    );
  }
}

export function* watchGetVoteDetail() {
  yield takeEvery(VOTE_GET_DETAILS, getVoteDetail);
}
export function* watchAddVoteOption() {
  yield takeEvery(VOTE_ADD_OPTION_REQUEST, addVoteOption);
}
export function* watchEditSession() {
  yield takeEvery(VOTE_EDIT_OPTION_REQUEST, editSession);
}
export function* watchDeleteSession() {
  yield takeEvery(VOTE_DELETE_SESSION_REQUEST, deleteSession);
}
export function* watchGetResidents() {
  yield takeEvery(VOTE_GET_RESIDENTS_REQUEST, getResidents);
}
export function* watchVoteCheckin() {
  yield takeEvery(VOTE_CHECKIN_REQUEST, addVoteCheckin);
}
export function* watchSetStatusSession() {
  yield takeEvery(VOTE_SET_STATUS_SESSION_REQUEST, setStatusSession);
}
export function* watchSaveResidentFile() {
  yield takeEvery(CHECKIN_SAVE_FILE_REQUEST, saveFileResident);
}
export function* watchActiveCheckin() {
  yield takeEvery(VOTE_ACTIVE_CHECKIN_REQUEST, activeCheckin);
}

export default function* rootSaga() {
  yield all([fork(watchGetVoteDetail)]);
  yield all([fork(watchAddVoteOption)]);
  yield all([fork(watchEditSession)]);
  yield all([fork(watchDeleteSession)]);
  yield all([fork(watchGetResidents)]);
  yield all([fork(watchVoteCheckin)]);
  yield all([fork(watchSetStatusSession)]);
  yield all([fork(watchSaveResidentFile)]);
  yield all([fork(watchActiveCheckin)]);
}
