/* eslint-disable import/no-cycle */
/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const CHANGE_CONDOMINIUM = 'CHANGE_CONDOMINIUM';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const ADD_USER_IMAGE = 'ADD_USER_IMAGE';
export const ADD_USER_IMAGE_SUCCESS = 'ADD_USER_IMAGE_SUCCESS';
export const ADD_USER_IMAGE_ERROR = 'ADD_USER_IMAGE_ERROR';
export const DELETE_USER_IMAGE = 'DELETE_USER_IMAGE';
export const DELETE_USER_IMAGE_SUCCESS = 'DELETE_USER_IMAGE_SUCCESS';
export const DELETE_USER_IMAGE_ERROR = 'DELETE_USER_IMAGE_ERROR';
export const UPDATE_EMAIL = 'EMAIL_PASSWORD';
export const UPDATE_EMAIL_SUCCESS = 'EMAIL_PASSWORD_SUCCESS';
export const UPDATE_EMAIL_ERROR = 'EMAIL_PASSWORD_ERROR';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* TODOAPP */
export const TODO_GET_LIST = 'TODO_GET_LIST';
export const TODO_GET_LIST_SUCCESS = 'TODO_GET_LIST_SUCCESS';
export const TODO_GET_LIST_ERROR = 'TODO_GET_LIST_ERROR';
export const TODO_GET_LIST_WITH_FILTER = 'TODO_GET_LIST_WITH_FILTER';
export const TODO_GET_LIST_WITH_ORDER = 'TODO_GET_LIST_WITH_ORDER';
export const TODO_GET_LIST_SEARCH = 'TODO_GET_LIST_SEARCH';
export const TODO_ADD_ITEM = 'TODO_ADD_ITEM';
export const TODO_ADD_ITEM_SUCCESS = 'TODO_ADD_ITEM_SUCCESS';
export const TODO_ADD_ITEM_ERROR = 'TODO_ADD_ITEM_ERROR';
export const TODO_SELECTED_ITEMS_CHANGE = 'TODO_SELECTED_ITEMS_CHANGE';

/* CHAT APP */
export const CHAT_GET_CONTACTS = 'CHAT_GET_CONTACTS';
export const CHAT_GET_CONTACTS_SUCCESS = 'CHAT_GET_CONTACTS_SUCCESS';
export const CHAT_GET_CONTACTS_ERROR = 'CHAT_GET_CONTACTS_ERROR';
export const CHAT_GET_CONVERSATIONS = 'CHAT_GET_CONVERSATIONS';
export const CHAT_GET_CONVERSATIONS_SUCCESS = 'CHAT_GET_CONVERSATIONS_SUCCESS';
export const CHAT_GET_CONVERSATIONS_ERROR = 'CHAT_GET_CONVERSATIONS_ERROR';
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  'CHAT_ADD_MESSAGE_TO_CONVERSATION';
export const CHAT_CREATE_CONVERSATION = 'CHAT_CREATE_CONVERSATION';
export const CHAT_SEARCH_CONTACT = 'CHAT_SEARCH_CONTACT';
export const CHAT_CHANGE_CONVERSATION = 'CHAT_CHANGE_CONVERSATION';

/* SURVEY LIST APP */
export const SURVEY_LIST_GET_LIST = 'SURVEY_LIST_GET_LIST';
export const SURVEY_LIST_GET_LIST_SUCCESS = 'SURVEY_LIST_GET_LIST_SUCCESS';
export const SURVEY_LIST_GET_LIST_ERROR = 'SURVEY_LIST_GET_LIST_ERROR';
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
  'SURVEY_LIST_GET_LIST_WITH_FILTER';
export const SURVEY_LIST_GET_LIST_WITH_ORDER =
  'SURVEY_LIST_GET_LIST_WITH_ORDER';
export const SURVEY_LIST_GET_LIST_SEARCH = 'SURVEY_LIST_GET_LIST_SEARCH';
export const SURVEY_LIST_ADD_ITEM = 'SURVEY_LIST_ADD_ITEM';
export const SURVEY_LIST_ADD_ITEM_SUCCESS = 'SURVEY_LIST_ADD_ITEM_SUCCESS';
export const SURVEY_LIST_ADD_ITEM_ERROR = 'SURVEY_LIST_ADD_ITEM_ERROR';
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
  'SURVEY_LIST_SELECTED_ITEMS_CHANGE';
export const SURVEY_EDIT_REQUEST = 'SURVEY_EDIT_REQUEST';
export const SURVEY_EDIT_SUCCESS = 'SURVEY_EDIT_SUCCESS';
export const SURVEY_DELETE_REQUEST = 'SURVEY_DELETE_REQUEST';
export const SURVEY_DELETE_SUCCESS = 'SURVEY_DELETE_SUCCESS';
export const SURVEY_ERROR = 'SURVEY_ERROR';
export const CATEGORY_ADD_REQUEST = 'CATEGORY_ADD_REQUEST';
export const CATEGORY_ADD_SUCCESS = 'CATEGORY_ADD_SUCCESS';
export const CATEGORY_ADD_FAILURE = 'CATEGORY_ADD_FAILURE';
export const CATEGORY_GET_SUCCESS = 'CATEGORY_GET_SUCCESS';
export const CATEGORY_GET_FAILURE = 'CATEGORY_GET_FAILURE';
export const CATEGORY_EDIT_REQUEST = 'CATEGORY_EDIT_REQUEST';
export const CATEGORY_EDIT_SUCCESS = 'CATEGORY_EDIT_SUCCESS';
export const CATEGORY_EDIT_FAILURE = 'CATEGORY_EDIT_FAILURE';
export const CATEGORY_DELETE_REQUEST = 'CATEGORY_DELETE_REQUEST';
export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS';
export const CATEGORY_DELETE_FAILURE = 'CATEGORY_DELETE_FAILURE';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_TOTAL_PAGE = 'SET_TOTAL_PAGE';
export const SET_TOTAL_PAGE_SUCCESS = 'SET_TOTAL_PAGE_SUCCESS';

/* SURVEY DETAIL APP */
export const SURVEY_GET_DETAILS = 'SURVEY_GET_DETAILS';
export const SURVEY_GET_DETAILS_SUCCESS = 'SURVEY_GET_DETAILS_SUCCESS';
export const SURVEY_GET_DETAILS_ERROR = 'SURVEY_GET_DETAILS_ERROR';
export const SURVEY_DELETE_QUESTION = 'SURVEY_DELETE_QUESTION';
export const SURVEY_DELETE_QUESTION_SUCCESS = 'SURVEY_DELETE_QUESTION_SUCCESS';
export const SURVEY_SAVE = 'SURVEY_SAVE';
export const QUESTIONS_ADD_REQUEST = 'QUESTIONS_ADD_REQUEST';
export const QUESTIONS_ADD_SUCCESS = 'QUESTIONS_ADD_SUCCESS';
export const QUESTIONS_EDIT_REQUEST = 'QUESTIONS_EDIT_REQUEST';
export const QUESTIONS_EDIT_SUCCESS = 'QUESTIONS_EDIT_SUCCESS';

/* VOTES */
export const VOTE_CATEGORY_FAILURE = 'VOTE_CATEGORY_FAILURE';
export const VOTE_CATEGORY_GET_SUCCESS = 'VOTE_CATEGORY_GET_SUCCESS';
export const VOTE_CATEGORY_ADD_REQUEST = 'VOTE_CATEGORY_ADD_REQUEST';
export const VOTE_CATEGORY_ADD_SUCCESS = 'VOTE_CATEGORY_ADD_SUCCESS';
export const VOTE_CATEGORY_EDIT_REQUEST = 'VOTE_CATEGORY_EDIT_REQUEST';
export const VOTE_CATEGORY_EDIT_SUCCESS = 'VOTE_CATEGORY_EDIT_SUCCESS';
export const VOTE_CATEGORY_DELETE_REQUEST = 'VOTE_CATEGORY_DELETE_REQUEST';
export const VOTE_CATEGORY_DELETE_SUCCESS = 'VOTE_CATEGORY_DELETE_SUCCESS';
export const VOTE_FAILURE = 'VOTE_FAILURE';
export const VOTE_GET_LIST = 'VOTE_GET_LIST';
export const VOTE_GET_LIST_SUCCESS = 'VOTE_GET_LIST_SUCCESS';
export const VOTE_ADD_REQUEST = 'VOTE_ADD_REQUEST';
export const VOTE_ADD_SUCCESS = 'VOTE_ADD_SUCCESS';
export const VOTE_EDIT_REQUEST = 'VOTE_EDIT_REQUEST';
export const VOTE_EDIT_SUCCESS = 'VOTE_EDIT_SUCCESS';
export const VOTE_DELETE_REQUEST = 'VOTE_DELETE_REQUEST';
export const VOTE_DELETE_SUCCESS = 'VOTE_DELETE_SUCCESS';
export const VOTE_SET_TOTAL_PAGE = 'VOTE_SET_TOTAL_PAGE';
export const VOTE_SET_CURRENT_PAGE = 'VOTE_SET_CURRENT_PAGE';
export const VOTE_SET_TOTAL_PAGE_SUCCESS = 'VOTE_SET_TOTAL_PAGE_SUCCESS';
export const VOTE_LIST_GET_LIST_WITH_FILTER = 'VOTE_LIST_GET_LIST_WITH_FILTER';
export const VOTE_GET_DETAILS = 'VOTE_GET_DETAILS';
export const VOTE_DETAILS_SUCCESS = 'VOTE_DETAILS_SUCCESS';
export const VOTE_DETAILS_ERROR = 'VOTE_DETAILS_ERROR';
export const VOTE_SAVE = 'VOTE_SAVE';
export const VOTE_ADD_OPTION_REQUEST = 'VOTE_ADD_OPTION_REQUEST';
export const VOTE_ADD_OPTION_SUCCESS = 'VOTE_ADD_OPTION_SUCCESS';
export const VOTE_EDIT_OPTION_REQUEST = 'VOTE_EDIT_OPTION_REQUEST';
export const VOTE_EDIT_OPTION_SUCCESS = 'VOTE_EDIT_OPTION_SUCCESS';
export const VOTE_DELETE_SESSION_REQUEST = 'VOTE_DELETE_SESSION_REQUEST';
export const VOTE_DELETE_SESSION_SUCCESS = 'VOTE_DELETE_SESSION_SUCCESS';
export const VOTE_GET_RESIDENTS_REQUEST = 'VOTE_GET_RESIDENTS_REQUEST';
export const VOTE_GET_RESIDENTS_SUCCESS = 'VOTE_GET_RESIDENTS_SUCCESS';
export const VOTE_CHECKIN_REQUEST = 'VOTE_CHECKIN_REQUEST';
export const VOTE_CHECKIN_SUCCESS = 'VOTE_CHECKIN_SUCCESS';
export const VOTE_CHECKIN_ERROR = 'VOTE_CHECKIN_ERROR';
export const VOTE_SET_STATUS_SESSION_REQUEST =
  'VOTE_SET_STATUS_SESSION_REQUEST';
export const VOTE_SET_STATUS_SESSION_SUCCESS =
  'VOTE_SET_STATUS_SESSION_SUCCESS';
export const VOTE_GET_RESIDENTS_WITH_FILTER = 'VOTE_GET_RESIDENTS_WITH_FILTER';
export const CHECKIN_SAVE_FILE_REQUEST = 'CHECKIN_SAVE_FILE_REQUEST';
export const CHECKIN_SAVE_FILE_SUCCESS = 'CHECKIN_SAVE_FILE_SUCCESS';
export const VOTE_ACTIVE_CHECKIN_REQUEST = 'VOTE_ACTIVE_CHECKIN_REQUEST';
export const VOTE_ACTIVE_CHECKIN_SUCCESS = 'VOTE_ACTIVE_CHECKIN_SUCCESS';

/* SELECT PERMISSIONS */
export const CLEAR_SELECT_PERMISSIONS = 'CLEAR_SELECT_PERMISSIONS';
export const SELECT_PERMISSIONS = 'SELECT_PERMISSIONS';
export const REQUEST_API_PERMISSIONS = 'REQUEST_API_PERMISSIONS';

export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';
export * from './todo/actions';
export * from './chat/actions';
export * from './surveyList/actions';
export * from './surveyDetail/actions';
export * from './voteList/actions';
export * from './voteDetail/actions';
