import {
  VOTE_GET_DETAILS,
  VOTE_DETAILS_SUCCESS,
  VOTE_DETAILS_ERROR,
  VOTE_SAVE,
  VOTE_ADD_OPTION_REQUEST,
  VOTE_ADD_OPTION_SUCCESS,
  VOTE_EDIT_OPTION_REQUEST,
  VOTE_EDIT_OPTION_SUCCESS,
  VOTE_DELETE_SESSION_REQUEST,
  VOTE_DELETE_SESSION_SUCCESS,
  VOTE_GET_RESIDENTS_REQUEST,
  VOTE_GET_RESIDENTS_SUCCESS,
  VOTE_CHECKIN_REQUEST,
  VOTE_CHECKIN_SUCCESS,
  VOTE_CHECKIN_ERROR,
  VOTE_SET_STATUS_SESSION_REQUEST,
  VOTE_SET_STATUS_SESSION_SUCCESS,
  VOTE_GET_RESIDENTS_WITH_FILTER,
  CHECKIN_SAVE_FILE_REQUEST,
  CHECKIN_SAVE_FILE_SUCCESS,
  VOTE_ACTIVE_CHECKIN_REQUEST,
  VOTE_ACTIVE_CHECKIN_SUCCESS,
} from '../actions';

const INIT_STATE = {
  vote: null,
  loading: false,
  units: [],
  allUnits: [],
  searchValue: null,
  canCheckin: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case VOTE_GET_DETAILS:
      return { ...state, loading: false };

    case VOTE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: true,
        vote: action.payload,
        canCheckin: action.payload.canCheckin,
      };

    case VOTE_DETAILS_ERROR:
      return { ...state, loading: true, error: action.payload };

    case VOTE_SAVE:
      return {
        ...state,
        loading: true,
        vote: action.payload,
        canCheckin: action.payload.canCheckin,
      };

    case VOTE_ADD_OPTION_REQUEST:
      return { ...state, loading: false };

    case VOTE_ADD_OPTION_SUCCESS:
      return {
        ...state,
        loading: true,
        vote: {
          ...state.vote,
          votingSessions: state.vote.votingSessions.map((session) => {
            if (session.id === action.payload.id) {
              return action.payload.response;
            }
            return session;
          }),
        },
      };

    case VOTE_EDIT_OPTION_REQUEST:
      return { ...state, loading: false };

    case VOTE_EDIT_OPTION_SUCCESS:
      return {
        ...state,
        loading: true,
        vote: {
          ...state.vote,
          votingSessions: state.vote.votingSessions.map((session) => {
            if (session.id === action.payload.id) {
              return action.payload;
            }
            return session;
          }),
        },
      };

    case VOTE_DELETE_SESSION_REQUEST:
      return { ...state, loading: false };

    case VOTE_DELETE_SESSION_SUCCESS:
      return {
        ...state,
        loading: true,
        vote: {
          ...state.vote,
          votingSessions: state.vote.votingSessions.filter(
            (session) => session.id !== action.payload.id
          ),
        },
      };

    case VOTE_GET_RESIDENTS_REQUEST:
      return { ...state, loading: false };

    case VOTE_GET_RESIDENTS_SUCCESS:
      return {
        ...state,
        loading: true,
        units: action.payload,
        allUnits: action.payload,
      };

    case VOTE_CHECKIN_REQUEST:
      return { ...state, loading: false };

    case VOTE_CHECKIN_SUCCESS:
      return { ...state, loading: true, units: action.payload };

    case VOTE_CHECKIN_ERROR:
      return { ...state, loading: true, error: action.payload };

    case VOTE_SET_STATUS_SESSION_REQUEST:
      return { ...state, loading: false };

    case VOTE_SET_STATUS_SESSION_SUCCESS:
      return {
        ...state,
        loading: true,
        vote: {
          ...state.vote,
          votingSessions: state.vote.votingSessions.map((session) => {
            if (session.id === action.payload.id) {
              return action.payload;
            }
            return session;
          }),
        },
      };

    case VOTE_GET_RESIDENTS_WITH_FILTER:
      // eslint-disable-next-line no-case-declarations
      const searchText = action.payload.toLowerCase();

      return {
        ...state,
        loading: true,
        units: state.allUnits.filter((item) => {
          const fullName =
            `${item.voteAccess.name} ${item.voteAccess.lastname}`.toLowerCase();
          return fullName.includes(searchText);
        }),
        searchValue: action.payload,
      };

    case CHECKIN_SAVE_FILE_REQUEST:
      return { ...state, loading: false };

    case CHECKIN_SAVE_FILE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const updatedPayload = {
        ...action.payload,
        address: action.payload.address.id,
        resident: action.payload.resident.id,
        residentType: action.payload.residentType.id,
      };
      // eslint-disable-next-line no-case-declarations
      const newUnits = state.allUnits.map((unit) => {
        if (unit.id === action.payload.address.id) {
          return {
            ...unit,
            residentDetails: unit.residentDetails.map((detail) =>
              detail.id === action.payload.id ? updatedPayload : detail
            ),
          };
        }
        return unit;
      });

      return {
        ...state,
        loading: true,
        units: newUnits,
        allUnits: newUnits,
      };

    case VOTE_ACTIVE_CHECKIN_REQUEST:
      return { ...state, loading: false };

    case VOTE_ACTIVE_CHECKIN_SUCCESS:
      return {
        ...state,
        loading: true,
        canCheckin: action.payload.canCheckin,
      };

    default:
      return { ...state };
  }
};
