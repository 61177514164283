/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Notification = ({
  title,
  type,
  message,
  customClassName,
  timeOut,
  onClick,
  onRequestHide,
}) => {
  const requestHide = () => {
    if (onRequestHide) {
      onRequestHide();
    }
  };

  useEffect(() => {
    let timer = null;
    if (timeOut !== 0) {
      timer = setTimeout(requestHide, timeOut);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    requestHide();
  };

  const className = classnames([
    'notification',
    `notification-${type}`,
    customClassName,
  ]);
  const titleHtml = title ? <h4 className="title">{title}</h4> : null;
  return (
    <div>
      <div className={`${className} text-white`}>
        <div className="w-50 h-50 text-right w-100 mb-0 pb-0">
          <button
            type="button"
            className="bg-transparent border-0 "
            onClick={() => handleClick()}
          >
            <CloseIcon />
          </button>
        </div>
        <div className="notification-message text-center" role="alert">
          {titleHtml}
          <div className="message">{message}</div>
        </div>
      </div>
    </div>
  );
};

const CloseIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8_437)">
        <path
          d="M16.38 31.76C24.8741 31.76 31.76 24.8741 31.76 16.38C31.76 7.88586 24.8741 1 16.38 1C7.88586 1 1 7.88586 1 16.38C1 24.8741 7.88586 31.76 16.38 31.76Z"
          stroke="#ffffff"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M11.0601 11.1399L21.7001 21.6099"
          stroke="#ffffff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.7001 11.1399L11.0601 21.6099"
          stroke="#ffffff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8_437">
          <rect width="32.76" height="32.76" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

Notification.propTypes = {
  type: PropTypes.oneOf([
    'info',
    'success',
    'warning',
    'error',
    'primary',
    'secondary',
  ]),
  title: PropTypes.node,
  message: PropTypes.node,
  timeOut: PropTypes.number,
  onClick: PropTypes.func,
  onRequestHide: PropTypes.func,
  customClassName: PropTypes.string,
};

Notification.defaultProps = {
  type: 'info',
  title: null,
  message: null,
  timeOut: 5000,
  onClick: () => {},
  onRequestHide: () => {},
  customClassName: '',
};

export default Notification;
