import {
  VOTE_CATEGORY_FAILURE,
  VOTE_CATEGORY_GET_SUCCESS,
  VOTE_CATEGORY_ADD_REQUEST,
  VOTE_CATEGORY_ADD_SUCCESS,
  VOTE_CATEGORY_EDIT_REQUEST,
  VOTE_CATEGORY_EDIT_SUCCESS,
  VOTE_CATEGORY_DELETE_REQUEST,
  VOTE_CATEGORY_DELETE_SUCCESS,
  VOTE_FAILURE,
  VOTE_GET_LIST,
  VOTE_GET_LIST_SUCCESS,
  VOTE_ADD_REQUEST,
  VOTE_ADD_SUCCESS,
  VOTE_EDIT_REQUEST,
  VOTE_EDIT_SUCCESS,
  VOTE_DELETE_REQUEST,
  VOTE_DELETE_SUCCESS,
  VOTE_SET_TOTAL_PAGE,
  VOTE_SET_CURRENT_PAGE,
  VOTE_SET_TOTAL_PAGE_SUCCESS,
  VOTE_LIST_GET_LIST_WITH_FILTER,
} from '../actions';

export const voteCategoryFailure = (error) => ({
  type: VOTE_CATEGORY_FAILURE,
  payload: error,
});

export const getVoteCategoriesSuccess = (categories) => ({
  type: VOTE_CATEGORY_GET_SUCCESS,
  payload: categories,
});

export const addVoteCategoryRequest = (categoryData) => ({
  type: VOTE_CATEGORY_ADD_REQUEST,
  payload: categoryData,
});

export const addVoteCategorySuccess = (category) => ({
  type: VOTE_CATEGORY_ADD_SUCCESS,
  payload: category,
});

export const editVoteCategoryRequest = (categoryData) => ({
  type: VOTE_CATEGORY_EDIT_REQUEST,
  payload: categoryData,
});

export const editVoteCategorySuccess = (category) => ({
  type: VOTE_CATEGORY_EDIT_SUCCESS,
  payload: category,
});

export const deleteVoteCategoryRequest = (categoryId) => ({
  type: VOTE_CATEGORY_DELETE_REQUEST,
  payload: categoryId,
});

export const deleteVoteCategorySuccess = (categoryId) => ({
  type: VOTE_CATEGORY_DELETE_SUCCESS,
  payload: categoryId,
});

export const voteFailure = (error) => ({
  type: VOTE_FAILURE,
  payload: error,
});

export const getVoteList = () => ({
  type: VOTE_GET_LIST,
});

export const getVoteListSuccess = (items) => ({
  type: VOTE_GET_LIST_SUCCESS,
  payload: items,
});

export const addVoteRequest = (item) => ({
  type: VOTE_ADD_REQUEST,
  payload: item,
});

export const addVoteSuccess = (items) => ({
  type: VOTE_ADD_SUCCESS,
  payload: items,
});

export const editVoteRequest = (data) => ({
  type: VOTE_EDIT_REQUEST,
  payload: data,
});

export const editVoteSuccess = (item) => ({
  type: VOTE_EDIT_SUCCESS,
  payload: item,
});

export const deleteVoteRequest = (item) => ({
  type: VOTE_DELETE_REQUEST,
  payload: item,
});

export const deleteVoteSuccess = (item) => ({
  type: VOTE_DELETE_SUCCESS,
  payload: item,
});

export const setVoteTotalPage = (data) => ({
  type: VOTE_SET_TOTAL_PAGE,
  payload: data,
});

export const setVoteCurrentPage = (page) => ({
  type: VOTE_SET_CURRENT_PAGE,
  payload: page,
});

export const getVoteTotalPageSuccess = (items) => ({
  type: VOTE_SET_TOTAL_PAGE_SUCCESS,
  payload: items,
});

export const getVoteListWithFilter = (column, value) => ({
  type: VOTE_LIST_GET_LIST_WITH_FILTER,
  payload: { column, value },
});
