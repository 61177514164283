import {
  VOTE_GET_DETAILS,
  VOTE_DETAILS_SUCCESS,
  VOTE_DETAILS_ERROR,
  VOTE_SAVE,
  VOTE_ADD_OPTION_REQUEST,
  VOTE_ADD_OPTION_SUCCESS,
  VOTE_EDIT_OPTION_REQUEST,
  VOTE_EDIT_OPTION_SUCCESS,
  VOTE_DELETE_SESSION_REQUEST,
  VOTE_DELETE_SESSION_SUCCESS,
  VOTE_GET_RESIDENTS_REQUEST,
  VOTE_GET_RESIDENTS_SUCCESS,
  VOTE_CHECKIN_REQUEST,
  VOTE_CHECKIN_SUCCESS,
  VOTE_CHECKIN_ERROR,
  VOTE_SET_STATUS_SESSION_REQUEST,
  VOTE_SET_STATUS_SESSION_SUCCESS,
  VOTE_GET_RESIDENTS_WITH_FILTER,
  CHECKIN_SAVE_FILE_REQUEST,
  CHECKIN_SAVE_FILE_SUCCESS,
  VOTE_ACTIVE_CHECKIN_REQUEST,
  VOTE_ACTIVE_CHECKIN_SUCCESS,
} from '../actions';

export const getVoteDetails = (item) => ({
  type: VOTE_GET_DETAILS,
  payload: item,
});

export const getVoteDetailsSuccess = (item) => ({
  type: VOTE_DETAILS_SUCCESS,
  payload: item,
});

export const addVoteDetailsFailure = (item) => ({
  type: VOTE_DETAILS_ERROR,
  payload: item,
});

export const saveVote = (item) => ({
  type: VOTE_SAVE,
  payload: item,
});

export const addVoteOptionRequest = (item) => ({
  type: VOTE_ADD_OPTION_REQUEST,
  payload: item,
});

export const addVoteOptionSuccess = (item) => ({
  type: VOTE_ADD_OPTION_SUCCESS,
  payload: item,
});

export const editVoteOptionRequest = (item) => ({
  type: VOTE_EDIT_OPTION_REQUEST,
  payload: item,
});

export const editVoteOptionSuccess = (item) => ({
  type: VOTE_EDIT_OPTION_SUCCESS,
  payload: item,
});

export const deleteVoteSessionRequest = (item) => ({
  type: VOTE_DELETE_SESSION_REQUEST,
  payload: item,
});

export const deleteVoteSessionSucess = (item) => ({
  type: VOTE_DELETE_SESSION_SUCCESS,
  payload: item,
});

export const getVoteResidentRequest = () => ({
  type: VOTE_GET_RESIDENTS_REQUEST,
});

export const getVoteResidentSuccess = (item) => ({
  type: VOTE_GET_RESIDENTS_SUCCESS,
  payload: item,
});

export const voteCheckinRequest = (item) => ({
  type: VOTE_CHECKIN_REQUEST,
  payload: item,
});

export const voteCheckinSuccess = (item) => ({
  type: VOTE_CHECKIN_SUCCESS,
  payload: item,
});

export const voteCheckinFailure = (item) => ({
  type: VOTE_CHECKIN_ERROR,
  payload: item,
});

export const setSessionStatusRequest = (item) => ({
  type: VOTE_SET_STATUS_SESSION_REQUEST,
  payload: item,
});

export const setSessionStatusSuccess = (item) => ({
  type: VOTE_SET_STATUS_SESSION_SUCCESS,
  payload: item,
});

export const getResidentsWithFilterRequest = (item) => ({
  type: VOTE_GET_RESIDENTS_WITH_FILTER,
  payload: item,
});

export const saveFileResidenteRequest = (item) => ({
  type: CHECKIN_SAVE_FILE_REQUEST,
  payload: item,
});

export const saveFileResidenteSuccess = (item) => ({
  type: CHECKIN_SAVE_FILE_SUCCESS,
  payload: item,
});

export const setActiveCheckinRequest = () => ({
  type: VOTE_ACTIVE_CHECKIN_REQUEST,
});

export const setActiveCheckinSuccess = (item) => ({
  type: VOTE_ACTIVE_CHECKIN_SUCCESS,
  payload: item,
});
