import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { servicePath } from 'constants/defaultValues';
import axios from 'axios';
import { NotificationManager } from 'components/common/react-notifications';
import {
  SURVEY_LIST_GET_LIST,
  SURVEY_LIST_ADD_ITEM,
  SURVEY_EDIT_REQUEST,
  SURVEY_DELETE_REQUEST,
  CATEGORY_ADD_REQUEST,
  CATEGORY_EDIT_REQUEST,
  CATEGORY_DELETE_REQUEST,
  SET_CURRENT_PAGE,
} from '../actions';

import {
  getSurveyListSuccess,
  getSurveyListError,
  addSurveyItemSuccess,
  addSurveyItemError,
  editSurveySuccess,
  deleteSurveySuccess,
  surveyError,
  addCategorySuccess,
  addCategoryFailure,
  getCategoriesSuccess,
  getCategoriesFailure,
  editCategorySuccess,
  editCategoryFailure,
  deleteCategorySuccess,
  deleteCategoryFailure,
  setTotalPage,
  getTotalPageSuccess,
} from './actions';

const apiCategory = `${servicePath}/survey-categories`;
const apiSurveys = `${servicePath}/surveys`;

const getSurveyListCountRequest = async ({ token, condominium }) => {
  const response = await axios.get(
    `${apiSurveys}?condominium=${condominium}&_limit=-1&_sort=created_at:desc`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const getSurveyListRequest = async ({
  allSurveys,
  currentPage,
  itemsPerPage,
}) => {
  const start = (currentPage - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  const surveysOnPage = allSurveys.slice(start, end);

  return surveysOnPage;
};
const getCategoryListRequest = async ({ token, condominium }) => {
  const response = await axios.get(
    `${apiCategory}?condominium=${condominium}&_limit=-1&_sort=created_at:desc`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};
function* getSurveyListItems() {
  const token = yield select((state) => state.authUser.strapiToken);
  const condominium = yield select((state) => state.settings.condominium);
  const currentPage = yield select((state) => state.surveyListApp.currentPage);
  const itemsPerPage = yield select(
    (state) => state.surveyListApp.itemsPerPage
  );

  try {
    const [totalSurveyCount, categoryResponse] = yield all([
      call(getSurveyListCountRequest, { token, condominium }),
      call(getCategoryListRequest, { token, condominium }),
    ]);
    const surveyListResponse = yield call(getSurveyListRequest, {
      allSurveys: totalSurveyCount,
      currentPage,
      itemsPerPage,
    });

    const totalPages = Math.ceil(totalSurveyCount?.length / itemsPerPage);
    yield put(
      getSurveyListSuccess({
        list: surveyListResponse,
        total: totalSurveyCount,
      })
    );
    yield put(getCategoriesSuccess(categoryResponse));
    yield put(setTotalPage(totalPages));
  } catch (error) {
    yield put(getSurveyListError(error));
    yield put(getCategoriesFailure(error));
    NotificationManager.error(
      '',
      'Error, intente más tarde',
      5000,
      null,
      null,
      ''
    );
  }
}

const addCategoryRequest = async ({ payload, token }) => {
  const response = await axios.post(apiCategory, payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

function* addCategory({ payload }) {
  try {
    const token = yield select((state) => state.authUser.strapiToken);
    const categories = yield select((state) => state.surveyListApp.categories);

    const nameExists = categories.some(
      (category) => category.name === payload.name
    );
    if (nameExists) {
      yield put(addCategoryFailure());
      NotificationManager.error(
        '',
        'El nombre de la categoría ya existe, intente con otro nombre.',
        5000,
        null,
        null,
        ''
      );
      return;
    }

    const response = yield call(addCategoryRequest, { payload, token });
    yield put(addCategorySuccess(response));
    NotificationManager.success(
      '',
      `Se ha agregado una nueva categoria`,
      4000,
      null,
      null
    );
  } catch (error) {
    yield put(addCategoryFailure(error));
    NotificationManager.error(
      '',
      'Error, al agregar categoria, intente más tarde.',
      5000,
      null,
      null,
      ''
    );
  }
}
const editCategoryRequest = async ({ payload, token }) => {
  const { id, name } = payload;
  const response = await axios.put(
    `${apiCategory}/${id}`,
    { name },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

function* editCategory({ payload }) {
  const token = yield select((state) => state.authUser.strapiToken);
  try {
    const response = yield call(editCategoryRequest, { payload, token });
    yield put(editCategorySuccess(response));
    NotificationManager.success(
      '',
      `Se ha editado la categoría exitosamente`,
      4000,
      null,
      null
    );
  } catch (error) {
    yield put(editCategoryFailure(error));
    NotificationManager.error(
      '',
      'Error al editar la categoría. Intente de nuevo más tarde.',
      5000,
      null,
      null,
      ''
    );
  }
}

const deleteCategoryRequest = async ({ payload, token }) => {
  const response = await axios.delete(`${apiCategory}/${payload}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

function* deleteCategory({ payload }) {
  const token = yield select((state) => state.authUser.strapiToken);
  try {
    const response = yield call(deleteCategoryRequest, { payload, token });
    yield put(deleteCategorySuccess(response));
    NotificationManager.success(
      '',
      `Se ha eliminado la categoría exitosamente`,
      4000,
      null,
      null
    );
  } catch (error) {
    yield put(deleteCategoryFailure(error));
    NotificationManager.error(
      '',
      'Error al eliminar la categoría. Intente de nuevo más tarde.',
      5000,
      null,
      null,
      ''
    );
  }
}

const addSurveyItemRequest = async ({ payload, condominium, token }) => {
  const response = await axios.post(
    apiSurveys,
    { ...payload, condominium },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

function* addSurveyItem({ payload }) {
  const token = yield select((state) => state.authUser.strapiToken);
  const condominium = yield select((state) => state.settings.condominium);

  try {
    const response = yield call(addSurveyItemRequest, {
      payload,
      condominium,
      token,
    });
    yield put(addSurveyItemSuccess(response));
    NotificationManager.success('', `Se creo una encuesta`, 4000, null, null);
  } catch (error) {
    yield put(addSurveyItemError(error));
    NotificationManager.error(
      '',
      'Error al agregar encuesta.',
      5000,
      null,
      null,
      ''
    );
  }
}

const editSurveyRequest = async ({ payload, token }) => {
  const { id } = payload;
  const response = await axios.put(`${apiSurveys}/${id}`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

function* editSurvey({ payload }) {
  const token = yield select((state) => state.authUser.strapiToken);
  try {
    const response = yield call(editSurveyRequest, { payload, token });
    yield put(editSurveySuccess(response));
    NotificationManager.success(
      '',
      `Se ha editado la encuesta exitosamente`,
      4000,
      null,
      null
    );
  } catch (error) {
    yield put(surveyError(error));
    NotificationManager.error(
      '',
      'Error al editar la encuesta. Intente de nuevo más tarde.',
      5000,
      null,
      null,
      ''
    );
  }
}
const deleteSurveyRequest = async ({ payload, token }) => {
  const response = await axios.delete(`${apiSurveys}/${payload}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

function* deleteSurvey({ payload }) {
  const token = yield select((state) => state.authUser.strapiToken);
  try {
    const response = yield call(deleteSurveyRequest, { payload, token });
    yield put(deleteSurveySuccess(response));
    NotificationManager.success(
      '',
      `Se ha eliminado la encuesta exitosamente`,
      4000,
      null,
      null
    );
  } catch (error) {
    yield put(surveyError(error));
    NotificationManager.error(
      '',
      'Error al eliminar la encuesta. Intente de nuevo más tarde.',
      5000,
      null,
      null,
      ''
    );
  }
}
function* getSurveyPage({ payload }) {
  try {
    const allSurveyItems = yield select(
      (state) => state.surveyListApp.allSurveyItems
    );
    const allSurveyFilterItems = yield select(
      (state) => state.surveyListApp.allSurveyFilterItems
    );
    const filter = yield select((state) => state.surveyListApp.filter);
    const itemsPerPage = yield select(
      (state) => state.surveyListApp.itemsPerPage
    );

    const allItems = filter ? allSurveyFilterItems : allSurveyItems;

    const response = yield call(getSurveyListRequest, {
      allSurveys: allItems,
      currentPage: payload,
      itemsPerPage,
    });

    yield put(getTotalPageSuccess(response));
  } catch (error) {
    yield put(getSurveyListError(error));
    NotificationManager.error(
      '',
      'Error, intente más tarde',
      5000,
      null,
      null,
      ''
    );
  }
}

export function* watchGetList() {
  yield takeEvery(SURVEY_LIST_GET_LIST, getSurveyListItems);
}
export function* watchAddCategory() {
  yield takeEvery(CATEGORY_ADD_REQUEST, addCategory);
}
export function* wathcAddItem() {
  yield takeEvery(SURVEY_LIST_ADD_ITEM, addSurveyItem);
}
export function* watchEditCategory() {
  yield takeEvery(CATEGORY_EDIT_REQUEST, editCategory);
}
export function* watchDeleteCategory() {
  yield takeEvery(CATEGORY_DELETE_REQUEST, deleteCategory);
}
export function* watchEditSurvey() {
  yield takeEvery(SURVEY_EDIT_REQUEST, editSurvey);
}
export function* watchDeleteSurvey() {
  yield takeEvery(SURVEY_DELETE_REQUEST, deleteSurvey);
}
export function* watchSetCurrentPage() {
  yield takeEvery(SET_CURRENT_PAGE, getSurveyPage);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(wathcAddItem),
    fork(watchAddCategory),
    fork(watchEditCategory),
    fork(watchDeleteCategory),
    fork(watchEditSurvey),
    fork(watchDeleteSurvey),
    fork(watchSetCurrentPage),
  ]);
}
