import {
  SURVEY_LIST_GET_LIST,
  SURVEY_LIST_GET_LIST_SUCCESS,
  SURVEY_LIST_GET_LIST_ERROR,
  SURVEY_LIST_GET_LIST_WITH_FILTER,
  SURVEY_LIST_GET_LIST_WITH_ORDER,
  SURVEY_LIST_GET_LIST_SEARCH,
  SURVEY_LIST_ADD_ITEM,
  SURVEY_LIST_ADD_ITEM_SUCCESS,
  SURVEY_LIST_ADD_ITEM_ERROR,
  SURVEY_LIST_SELECTED_ITEMS_CHANGE,
  SURVEY_EDIT_REQUEST,
  SURVEY_EDIT_SUCCESS,
  SURVEY_DELETE_REQUEST,
  SURVEY_DELETE_SUCCESS,
  SURVEY_ERROR,
  CATEGORY_ADD_REQUEST,
  CATEGORY_ADD_SUCCESS,
  CATEGORY_ADD_FAILURE,
  CATEGORY_GET_SUCCESS,
  CATEGORY_GET_FAILURE,
  CATEGORY_EDIT_REQUEST,
  CATEGORY_EDIT_SUCCESS,
  CATEGORY_EDIT_FAILURE,
  CATEGORY_DELETE_SUCCESS,
  SET_TOTAL_PAGE,
  SET_CURRENT_PAGE,
  SET_TOTAL_PAGE_SUCCESS,
} from '../actions';

const INIT_STATE = {
  allSurveyItems: null,
  allSurveyFilterItems: null,
  surveyItems: null,
  error: '',
  filter: null,
  searchKeyword: '',
  orderColumn: null,
  loading: false,
  labels: [
    { label: 'EDUCATION', color: 'secondary' },
    { label: 'NEW FRAMEWORK', color: 'primary' },
    { label: 'PERSONAL', color: 'info' },
  ],
  orderColumns: [
    { column: 'title', label: 'Title' },
    { column: 'category', label: 'Category' },
    { column: 'status', label: 'Status' },
    { column: 'label', label: 'Label' },
  ],
  categories: [],
  selectedItems: [],
  currentPage: 1,
  itemsPerPage: 5,
  totalPage: 1,
};

export default (state = INIT_STATE, action) => {
  let allItems;
  switch (action.type) {
    case SURVEY_LIST_GET_LIST:
      return { ...state, loading: false };

    case SURVEY_LIST_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        allSurveyItems: action.payload.total,
        surveyItems: action.payload.list,
        filter: null,
      };

    case SURVEY_LIST_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case SURVEY_LIST_GET_LIST_WITH_FILTER:
      if (action.payload.column === '' || action.payload.value === '') {
        return {
          ...state,
          totalPage: Math.ceil(
            state.allSurveyItems.length / state.itemsPerPage
          ),
          currentPage: 1,
          loading: true,
          surveyItems: state.allSurveyItems.slice(0, state.itemsPerPage),
          allSurveyFilterItems: null,
          filter: null,
        };
      }
      // eslint-disable-next-line no-case-declarations
      let filteredItems;
      if (action.payload.column === 'survey_category') {
        filteredItems = state.allSurveyItems.filter(
          (item) =>
            item.survey_category &&
            item.survey_category.id === action.payload.value
        );
      } else {
        filteredItems = state.allSurveyItems.filter(
          (item) => item[action.payload.column] === action.payload.value
        );
      }

      return {
        ...state,
        loading: true,
        totalPage: Math.ceil(filteredItems.length / state.itemsPerPage),
        currentPage: 1,
        surveyItems: filteredItems.slice(0, state.itemsPerPage),
        allSurveyFilterItems: filteredItems,
        filter: {
          column: action.payload.column,
          value: action.payload.value,
        },
      };

    case SURVEY_LIST_GET_LIST_WITH_ORDER:
      if (action.payload === '') {
        return {
          ...state,
          loading: true,
          surveyItems: state.surveyItems,
          orderColumn: null,
        };
      }
      // eslint-disable-next-line no-case-declarations
      const sortedItems = state.surveyItems.sort((a, b) => {
        if (a[action.payload] < b[action.payload]) return -1;
        if (a[action.payload] > b[action.payload]) return 1;
        return 0;
      });
      return {
        ...state,
        loading: true,
        surveyItems: sortedItems,
        orderColumn: state.orderColumns.find(
          (x) => x.column === action.payload
        ),
      };

    case SURVEY_LIST_GET_LIST_SEARCH:
      if (action.payload === '') {
        return { ...state, surveyItems: state.allSurveyItems };
      }
      // eslint-disable-next-line no-case-declarations
      const keyword = action.payload.toLowerCase();
      // eslint-disable-next-line no-case-declarations
      const searchItems = state.allSurveyItems.filter(
        (item) =>
          item.title.toLowerCase().indexOf(keyword) > -1 ||
          item.detail.toLowerCase().indexOf(keyword) > -1 ||
          item.status.toLowerCase().indexOf(keyword) > -1 ||
          item.category.toLowerCase().indexOf(keyword) > -1 ||
          item.label.toLowerCase().indexOf(keyword) > -1
      );
      return {
        ...state,
        loading: true,
        surveyItems: searchItems,
        searchKeyword: action.payload,
      };

    case SURVEY_LIST_ADD_ITEM:
      return { ...state, loading: false };

    case SURVEY_LIST_ADD_ITEM_SUCCESS:
      allItems = [action.payload, ...state.allSurveyItems];

      return {
        ...state,
        loading: true,
        currentPage: 1,
        totalPage: Math.ceil(allItems.length / state.itemsPerPage),
        allSurveyItems: allItems,
        surveyItems: allItems.slice(0, state.itemsPerPage),
      };

    case SURVEY_LIST_ADD_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };

    case SURVEY_LIST_SELECTED_ITEMS_CHANGE:
      return { ...state, loading: true, selectedItems: action.payload };

    case CATEGORY_ADD_REQUEST:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case SURVEY_EDIT_REQUEST:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case SURVEY_EDIT_SUCCESS:
      return {
        ...state,
        loading: true,
        allSurveyItems: state.allSurveyItems.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        }),
        surveyItems: state.surveyItems.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        }),
      };

    case SURVEY_DELETE_REQUEST:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case SURVEY_DELETE_SUCCESS:
      allItems = state.allSurveyItems.filter(
        (survey) => survey.id !== action.payload.id
      );
      return {
        ...state,
        loading: true,
        currentPage: 1,
        totalPage: Math.ceil(allItems.length / state.itemsPerPage),
        allSurveyItems: allItems,
        surveyItems: allItems.slice(0, state.itemsPerPage),
      };

    case SURVEY_ERROR:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };

    case CATEGORY_ADD_SUCCESS:
      return {
        ...state,
        loading: true,
        categories: [...state.categories, action.payload],
      };

    case CATEGORY_ADD_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };

    case CATEGORY_GET_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        loading: true,
        error: null,
      };

    case CATEGORY_GET_FAILURE:
      return {
        ...state,
        categories: [],
        loading: true,
        error: action.payload,
      };

    case CATEGORY_EDIT_REQUEST:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CATEGORY_EDIT_SUCCESS:
      return {
        ...state,
        loading: true,
        categories: state.categories.map((category) =>
          category.id === action.payload.id
            ? { ...category, name: action.payload.name }
            : category
        ),
      };

    case CATEGORY_EDIT_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };

    case CATEGORY_DELETE_SUCCESS:
      return {
        ...state,
        categories: state.categories.filter(
          (category) => category.id !== action.payload.id
        ),
      };

    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case SET_TOTAL_PAGE:
      return {
        ...state,
        totalPage: action.payload,
      };

    case SET_TOTAL_PAGE_SUCCESS:
      return {
        ...state,
        loading: true,
        surveyItems: action.payload,
      };

    default:
      return { ...state };
  }
};
