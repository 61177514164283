import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { servicePath } from 'constants/defaultValues';
import axios from 'axios';
import { NotificationManager } from 'components/common/react-notifications';

import {
  VOTE_CATEGORY_ADD_REQUEST,
  VOTE_CATEGORY_EDIT_REQUEST,
  VOTE_CATEGORY_DELETE_REQUEST,
  VOTE_GET_LIST,
  VOTE_ADD_REQUEST,
  VOTE_EDIT_REQUEST,
  VOTE_DELETE_REQUEST,
  VOTE_SET_CURRENT_PAGE,
} from '../actions';

import {
  voteCategoryFailure,
  getVoteCategoriesSuccess,
  addVoteCategorySuccess,
  editVoteCategorySuccess,
  deleteVoteCategorySuccess,
  voteFailure,
  getVoteListSuccess,
  addVoteSuccess,
  editVoteSuccess,
  deleteVoteSuccess,
  setVoteTotalPage,
  getVoteTotalPageSuccess,
} from './actions';

const apiCategory = `${servicePath}/voting-categories`;
const apiVote = `${servicePath}/votes`;

const addCategoryRequest = async ({ payload, token }) => {
  const response = await axios.post(apiCategory, payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};
const editCategoryRequest = async ({ payload, token }) => {
  const { id, name } = payload;
  const response = await axios.put(
    `${apiCategory}/${id}`,
    { name },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};
const deleteCategoryRequest = async ({ payload, token }) => {
  const response = await axios.delete(`${apiCategory}/${payload}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};
const getVoteListCountRequest = async ({ token, condominium }) => {
  const response = await axios.get(
    `${apiVote}?condominium=${condominium}&_limit=-1&_sort=created_at:desc`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};
const getCategoryListRequest = async ({ token, condominium }) => {
  const response = await axios.get(
    `${apiCategory}?condominium=${condominium}&_limit=-1&_sort=created_at:desc`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};
const getVoteListRequest = async ({ allItems, currentPage, itemsPerPage }) => {
  const start = (currentPage - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  const itemsOnPage = allItems.slice(start, end);

  return itemsOnPage;
};
const getVoteRequest = async ({ payload, condominium, token }) => {
  const response = await axios.post(
    apiVote,
    { ...payload, condominium },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};
const editVoteRequest = async ({ payload, token }) => {
  const { id } = payload;
  const response = await axios.put(`${apiVote}/${id}`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};
const deleteVoteRequest = async ({ payload, token }) => {
  const response = await axios.delete(`${apiVote}/${payload}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

function* addCategory({ payload }) {
  try {
    const token = yield select((state) => state.authUser.strapiToken);
    const categories = yield select((state) => state.voteListApp.categories);

    const nameExists = categories.some(
      (category) => category.name === payload.name
    );
    if (nameExists) {
      yield put(voteCategoryFailure());
      NotificationManager.error(
        '',
        'El nombre de la categoría ya existe, intente con otro nombre.',
        5000,
        null,
        null,
        ''
      );
      return;
    }

    const response = yield call(addCategoryRequest, { payload, token });
    yield put(addVoteCategorySuccess(response));
    NotificationManager.success(
      '',
      `Se ha agregado una nueva categoria`,
      4000,
      null,
      null
    );
  } catch (error) {
    yield put(voteCategoryFailure(error));
    NotificationManager.error(
      '',
      'Error, al agregar categoria, intente más tarde.',
      5000,
      null,
      null,
      ''
    );
  }
}
function* editCategory({ payload }) {
  const token = yield select((state) => state.authUser.strapiToken);
  try {
    const response = yield call(editCategoryRequest, { payload, token });
    yield put(editVoteCategorySuccess(response));
    NotificationManager.success(
      '',
      `Se ha editado la categoría exitosamente`,
      4000,
      null,
      null
    );
  } catch (error) {
    yield put(voteCategoryFailure(error));
    NotificationManager.error(
      '',
      'Error al editar la categoría. Intente de nuevo más tarde.',
      5000,
      null,
      null,
      ''
    );
  }
}
function* deleteCategory({ payload }) {
  const token = yield select((state) => state.authUser.strapiToken);
  try {
    const response = yield call(deleteCategoryRequest, { payload, token });
    yield put(deleteVoteCategorySuccess(response));
    NotificationManager.success(
      '',
      `Se ha eliminado la categoría exitosamente`,
      4000,
      null,
      null
    );
  } catch (error) {
    yield put(voteCategoryFailure(error));
    NotificationManager.error(
      '',
      'Error al eliminar la categoría. Intente de nuevo más tarde.',
      5000,
      null,
      null,
      ''
    );
  }
}
function* getSurveyListItems() {
  const token = yield select((state) => state.authUser.strapiToken);
  const condominium = yield select((state) => state.settings.condominium);
  const currentPage = yield select((state) => state.voteListApp.currentPage);
  const itemsPerPage = yield select((state) => state.voteListApp.itemsPerPage);

  try {
    const [totalVoteCount, categoryResponse] = yield all([
      call(getVoteListCountRequest, { token, condominium }),
      call(getCategoryListRequest, { token, condominium }),
    ]);
    const surveyListResponse = yield call(getVoteListRequest, {
      allItems: totalVoteCount,
      currentPage,
      itemsPerPage,
    });

    const totalPages = Math.ceil(totalVoteCount?.length / itemsPerPage);
    yield put(
      getVoteListSuccess({
        list: surveyListResponse,
        total: totalVoteCount,
      })
    );
    yield put(getVoteCategoriesSuccess(categoryResponse));
    yield put(setVoteTotalPage(totalPages));
  } catch (error) {
    yield put(voteFailure(error));
    NotificationManager.error(
      '',
      'Error, intente más tarde',
      5000,
      null,
      null,
      ''
    );
  }
}
function* addVoteItem({ payload }) {
  const token = yield select((state) => state.authUser.strapiToken);
  const condominium = yield select((state) => state.settings.condominium);

  try {
    const response = yield call(getVoteRequest, {
      payload,
      condominium,
      token,
    });
    yield put(addVoteSuccess(response));
    NotificationManager.success('', `Se creo una votación`, 4000, null, null);
  } catch (error) {
    yield put(voteFailure(error));
    NotificationManager.error(
      '',
      'Error al agregar votación.',
      5000,
      null,
      null,
      ''
    );
  }
}
function* editVote({ payload }) {
  const token = yield select((state) => state.authUser.strapiToken);
  try {
    const response = yield call(editVoteRequest, { payload, token });
    yield put(editVoteSuccess(response));
    NotificationManager.success(
      '',
      `Se ha editado la votación exitosamente`,
      4000,
      null,
      null
    );
  } catch (error) {
    yield put(voteFailure(error));
    NotificationManager.error(
      '',
      'Error al editar la votación. Intente de nuevo más tarde.',
      5000,
      null,
      null,
      ''
    );
  }
}
function* deleteVote({ payload }) {
  const token = yield select((state) => state.authUser.strapiToken);
  try {
    const response = yield call(deleteVoteRequest, { payload, token });
    yield put(deleteVoteSuccess(response));
    NotificationManager.success(
      '',
      `Se ha eliminado la votación exitosamente`,
      4000,
      null,
      null
    );
  } catch (error) {
    yield put(voteFailure(error));
    NotificationManager.error(
      '',
      'Error al eliminar la votación. Intente de nuevo más tarde.',
      5000,
      null,
      null,
      ''
    );
  }
}
function* getVotePage({ payload }) {
  try {
    const allVoteItems = yield select(
      (state) => state.voteListApp.allVoteItems
    );
    const allVoteFilterItems = yield select(
      (state) => state.voteListApp.allVoteFilterItems
    );
    const filter = yield select((state) => state.voteListApp.filter);
    const itemsPerPage = yield select(
      (state) => state.voteListApp.itemsPerPage
    );

    const allItems = filter ? allVoteFilterItems : allVoteItems;

    const response = yield call(getVoteListRequest, {
      allItems,
      currentPage: payload,
      itemsPerPage,
    });

    yield put(getVoteTotalPageSuccess(response));
  } catch (error) {
    yield put(voteFailure(error));
    NotificationManager.error(
      '',
      'Error, intente más tarde',
      5000,
      null,
      null,
      ''
    );
  }
}

export function* watchVoteAddCategory() {
  yield takeEvery(VOTE_CATEGORY_ADD_REQUEST, addCategory);
}
export function* watchVoteEditCategory() {
  yield takeEvery(VOTE_CATEGORY_EDIT_REQUEST, editCategory);
}
export function* watchVoteDeleteCategory() {
  yield takeEvery(VOTE_CATEGORY_DELETE_REQUEST, deleteCategory);
}
export function* watchVoteGetList() {
  yield takeEvery(VOTE_GET_LIST, getSurveyListItems);
}
export function* watchAddVote() {
  yield takeEvery(VOTE_ADD_REQUEST, addVoteItem);
}
export function* watchEditVote() {
  yield takeEvery(VOTE_EDIT_REQUEST, editVote);
}
export function* watchDeleteVote() {
  yield takeEvery(VOTE_DELETE_REQUEST, deleteVote);
}
export function* watchSetVoteCurrentPage() {
  yield takeEvery(VOTE_SET_CURRENT_PAGE, getVotePage);
}

export default function* rootSaga() {
  yield all([
    fork(watchVoteAddCategory),
    fork(watchVoteEditCategory),
    fork(watchVoteDeleteCategory),
    fork(watchVoteGetList),
    fork(watchAddVote),
    fork(watchEditVote),
    fork(watchDeleteVote),
    fork(watchSetVoteCurrentPage),
  ]);
}
